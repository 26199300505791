import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FooterTwoComponent } from "./footer-two.component";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule ({

    declarations: [
        FooterTwoComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
    ],
    exports: [
        FooterTwoComponent
    ]

})

export class FooterTwoModule { };