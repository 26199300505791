import { Component, OnInit, NgZone } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router'
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';
import { AppService } from 'src/app/app.service';
// import { HeaderService } from '../Header/header.service';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import {HeaderService} from './header.service';
import {config} from 'src/configs/config';
import {HostListener } from '@angular/core';
import { CheckoutLocalStorageService as CheckoutLocalStorageServiceNew } from 'src/app/Lazy-loaded-Modules/Checkout-new/checkout-local-storage.service';


@Component({
  selector: 'app-two-header',
  templateUrl: './header-two.component.html'
})
export class HeaderTwoComponent implements OnInit {

  searchForm: UntypedFormGroup;
  preList = [];
  userLoggedIn: boolean = false;
  isSearch: boolean = false;
  productOrList: boolean;
  productId: any;
  searchTerm: any;
  allCategories: any;
  logoImage : string;
  configs = config;
  reqData1;
  promotionBanner: any;
  fruits:any='fruits'
  vegetablesres:any='vegetablesres'
  othersres:any='othersres'
  fruitsres:any='fruitsres'
  vegetables:any='vegetables';
  others:any='others'
  pages:any='pages'
  @HostListener('window:scroll', ['$event']) // for window scroll events
  scrollFunction() {
    if(this.configs.title=='Vimpro'){
      var mybutton = document.getElementById("vimpro_cate");
    
    if (window.pageYOffset< 0 ) {
      
      mybutton.style.display = "block";
    } 
    if(window.pageYOffset== 0 ){
      mybutton.style.display = "block";

    }
    else {
      mybutton.style.display = "none";
    }
      
    }

    
  }

  resultMessage: any;
  constructor(
    config1: NgbDropdownConfig,
    // private authService: AuthTokenService,
    private localService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    public appService: AppService,
    private formBuilder: UntypedFormBuilder,
    // private cartService: CartService,
    private headerService: HeaderService,
    private checkoutLocalStorageService1: CheckoutLocalStorageServiceNew
  ) {
    config1.placement = 'bottom-right'
  }

  ngOnInit(): void {
    //console.log(appService.userName)

    // this.cartService.totalCartItems();
    // this.appService.cartProducts.results = this.cartService.getCartProducts();
    this.userLoggedIn = this.appService.loggedin_user;
    this.searchForm = new UntypedFormGroup({
      search_term: this.formBuilder.control('')
    })

    this.getAllCategories();

    this.getLogo();
  
    this.getPromotionBanner();

    // if (this.authService.getUserName() != 'undefined' && this.authService.getUserName()) {
    //   //The front-end should change when this is called.
    //   this.appService.userLoggedIn = true;
    //   //this.appService.userName = this.authService.getUserName();
    // }
    // else {
    //   this.appService.userLoggedIn = false;
    //   this.appService.userName = "";
    // }

    //TO CLEAR THE SEARCH TERM FROM OTHER EVENTS
    this.headerService.currentData.subscribe(
      currentData =>{
        if(currentData === 'clearSearchTerm'){
          this.closePrelist();
        }
      }
    )
  }


  getPreList(){
    let searchData = this.searchForm.value.search_term;
    if(searchData.length >=2){
      let reqData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras": {
          "find": {
            "search": searchData
          }
        }
      }
      this.appService.postApiCall(reqData, ENDPOINTS.GET_PRELIST).subscribe(
        resp => {
          this.preList = resp.result.data;
          
        },
        err => {
          if (err) {
            console.log("Unable to get prelist data: ", err.message)
          }
          else{
            console.log('Something bad happened; Please try again!')
          }
        })
        
    }
    
    else{
      this.preList = [];
    }
  }

  getPromotionBanner() {
    let respData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {

        }
      }
    }
    this.appService.postApiCall(respData, ENDPOINTS.PROMOTIONS_BANNER).subscribe(
      resp => {
        if(resp.message!="No records found"){
          this.promotionBanner=resp.result.data[0].eligible_banner_message
        }
      },
      err => {
        if (err) {
          console.log("Unable to get Promotion Banner: Error: ", err.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }


  hideBanner(){
    document.getElementById("banner").style.display="none"
  }

  savePreList(val){
    this.localService.remove("option1")
    this.localService.remove("option2")

    let userId = this.localService.get('UserId');
   if (this.configs.title === 'Carience') {
    if(userId){
      this.sendProductDetails(val);
     }
    else{
      this.logIn();
     // this.router.navigate(['/login-new'], { queryParams: { productId:product.id, },relativeTo:this.route,  skipLocationChange: false })
    }
   }
   else{
    if(this.configs.title=="kubendran"){
      document.getElementById("searchnav").style.width = "0%";

    }
    this.sendProductDetails(val);
  
    
   }
    //document.getElementById("searchnav").style.width = "0";
    
  }

  savePreList1(){
    var searchVal1 = this.searchForm.controls['search_term'].value;
    if(searchVal1){
      let searchData = {
        "name": searchVal1,
        "type": ""
    }
      this.sendProductDetails(searchData);
    }
  }

  logIn(){
    this.router.navigate([`/login-new`], {queryParams: { url: this.router.url }, relativeTo:this.route,  skipLocationChange: false });

  }
  

  logOut() {
    this.appService.userLoggedIn = false;
    this.appService.userName = "";
    console.log( this.appService.userName,"login user")

    this.appService.loggedin_user = false;
    this.userLoggedIn = this.appService.loggedin_user;
    this.localService.remove('accessToken');
    this.localService.remove('refreshToken');
    this.localService.remove('expireTime');
    this.localService.remove('UserName');
    this.localService.remove('UserId');
    this.localService.remove('domainName');
    this.localService.remove('rexpireTime');
    this.localService.remove('AiemaUserName');
    this.localService.remove('couponCode');
    // this.ngOnInit();
    // this.cartService.clearCart();
    //this.loggedIn = false;
    this.router.navigate(["/"]);
    this.checkoutLocalStorageService1.clearDataonLogout();
    this.checkoutLocalStorageService1.removeOrderId();
  }

  sendProductDetails(searchTerm){
    console.log("searchTerm"+searchTerm)
    let searchRequestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
          "search": searchTerm.name
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 35
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "id",
          "sortorder": 1
        }
      }
    }

    // MAKE AN API CALL
     this.appService.postApiCall(searchRequestData, ENDPOINTS.GET_SEARCH_RESULT).subscribe(
      resp => {
        let respData = resp.result.data;
        this.preList = [];
        //DIRECT TO PRODUCT DETAILS IF ID PRESENT
        console.log("respData.length1"+respData.length)
        if(respData.length === 1){
          console.log("respData.length2"+respData.length)
          this.searchForm.controls['search_term'].setValue(searchTerm.name);
          this.router.navigate(['/']).then(()=>
          {
            if(this.configs.title === "Aiema"  ){
              this.router.navigate([`/company-details1/${respData[0].id}`]);
            }
            else{
              console.log("respData.length3"+respData.length)
              this.router.navigate([`/product-details/${respData[0].id}`]);
            }
            
          })
        }
        else if (respData.length > 1){
          this.searchForm.controls['search_term'].setValue(searchTerm.name);
          this.router.navigate(['/']).then(()=>
          {
            if(this.configs.title === "BBold"){
              this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            } 
             else if(this.configs.title === "Beaubelle"){
              if(searchTerm.type=='Brand'){
              this.router.navigate([`/productListing`], {queryParams: { terms:[[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
              }
              else if(searchTerm.type=='Category'){
                this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
              }
             // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
                }
                else if(this.configs.title === "Kubendran"){
                  if(searchTerm.type=='Brand')
                  {
                    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[searchTerm.name],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    
    
                  }
                  else if(searchTerm.type=='Category')
                  {
                    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    
                  }
                  else
                  {
                    
                    this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
    
                  }
                  
                }
             
            else if(this.configs.title=="Mayan"){
              if(searchTerm.type=="Category")
              this.router.navigate([`/product`], {
                queryParams: {
                  terms: [searchTerm.name, searchTerm.type],
                  type: "searchresults",
                },
                relativeTo: this.route,
                skipLocationChange: false,
              });
              else{
                this.router.navigate([`/product-details/${respData[0].id}`]);
              }

            } 
              
            //this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
            // if(this.configs.title === "BBold"){
            //   this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            // } 
            else if (this.configs.title=="BombayHardware" || this.configs.title=="AvonSeals" || this.configs.title=="ShubhCards" ){
              if(searchTerm.type=='Category' && this.configs.title!='ShubhCards')
              {
                this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
               

              }
              if(this.configs.title=='ShubhCards' && searchTerm.type=='Category'){
                this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

              }

              else
              {
                
                this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
              }
            }
            else {
              this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });

            }
          })
        }
        else{
          this.searchForm.controls['search_term'].setValue(searchTerm.name);

          this.router.navigate(['/']).then(()=>
          {
            if(this.configs.title === "BBold"){
              this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            } 
            else if(this.configs.title === "Mayan" && (searchTerm.type=="Category")){
              this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            } 
            else if(this.configs.title === "Mayan" && (searchTerm.type!=="Category")){
              this.router.navigate([`/product-details/${respData[0].id}`])
            } 
            else if(this.configs.title === "Beaubelle"){
              if(searchTerm.type=='Brand'){
              this.router.navigate([`/productListing`], {queryParams: { terms:[[searchTerm.name],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
              }
              else if(searchTerm.type=='Category'){
                this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });


              }
             // this.router.navigate([`/productListing`], {queryParams: { terms:[[],[searchTerm.name],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
                }
            // if(this.configs.title === "BBold"){
            //   this.router.navigate([`/product`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
            // } 
            else {
              this.router.navigate([`/productListing`], {queryParams: { terms:[searchTerm.name, searchTerm.type], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });

            }
          })
        }
      },
      err => {
        if (err) {
          console.log("Error with product details: ", err.message)
        }
        else{
          console.log('Something bad happened; Please try again!')
        }
      })
  }

  getAllCategories() {
    if(this.configs.title==='BombayHardware' || this.configs.title==='AvonSeals'){
      this.reqData1 = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras": {
          "find": {
          },
          "pagination": false,
          "paginationDetails": {
            "limit": 1,
            "pagesize": 25
          },
          "sorting": true,
          "sortingDetails": {
            "sortfield": "sort_order",
            "sortorder": -1
          }
        }
      }
    } else {
      this.reqData1 = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras": {
          "find": {
          },
          "pagination": false,
          "paginationDetails": {
            "limit": 1,
            "pagesize": 25
          },
          "sorting": true,
          "sortingDetails": {
            "sortfield": "name",
            "sortorder": -1
          }
        }
      }
    }
		this.appService.postApiCall(this.reqData1, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
			resp => {
				this.allCategories = resp.result.data;
			},
			err => {
				if (err) {
					console.log(err)
				}
				else {
					console.log('Something bad happened; Please try again!')
				}
			})
	}

  getAllProductList(category){
    this.searchForm.controls['search_term'].setValue(category);
    this.router.navigate([`/productListing`], {queryParams: { terms:[category], type:'filter' }, relativeTo:this.route,  skipLocationChange: false });
  }
  

  getLogo(){
    let data = {
      "domain_name":ORG_DETAILS.DOMAIN_NAME,
      "user_id":ORG_DETAILS.USER_ID,
      "extras":{
      "find": {}
      }
  }
  this.appService.postApiCall(data, ENDPOINTS.STOREGALLERY).subscribe(
    resp => {
      let respData = resp.result.data[0];
      this.logoImage = respData.logo.logo_image_source
    },
    err => {
      if (err) {
        console.log("Unable to obtain logo \n, ERROR:", err.message);
      }
      else {
        console.log('Something bad happened; Please try again!')
      }
    })
  }
 openNavkub() {
    document.getElementById("myfruits").style.width = "0";
    document.getElementById("myvegetables").style.width = "0";
   // document.getElementById("myothers").style.width = "0";
    document.getElementById("mySidenav").style.width = "60%";
  }
  openNavkub1() {
    document.getElementById("myfruits1").style.width = "0";
    document.getElementById("myvegetables1").style.width = "0";
    //document.getElementById("myothers1").style.width = "0";
    document.getElementById("mySidenav1").style.width = "60%";
  }
  opensearchnavkub() {
    document.getElementById("searchnav").style.width = "100%";
    document.getElementById("mySidenav").style.width = "0";

  }
  closesearchnavkub() {
    document.getElementById("searchnav").style.width = "0";
    
  }
  closeNavkub1() {
    document.getElementById("mySidenav").style.width = "0";
    
  }
  closeNavkub11() {
    document.getElementById("mySidenav1").style.width = "0";
    
  }
  openNav() {
    document.getElementById("myfruits").style.width = "0";
    document.getElementById("myvegetables").style.width = "0";
    document.getElementById("myothers").style.width = "0";
    document.getElementById("mySidenav").style.width = "60%";
  }
  openNav1() {
    document.getElementById("myfruits1").style.width = "0";
    document.getElementById("myvegetables1").style.width = "0";
    document.getElementById("myothers1").style.width = "0";
    document.getElementById("mySidenav1").style.width = "60%";
  }
  
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    
  }
  closeNav1() {
    document.getElementById("mySidenav1").style.width = "0";
    
  }

  bestgifts(){
    this.productsList();
    this.closeNav();
  }

  search(){
    this.isSearch = true;
  }


  navigateToList(category) {
    //this.searchForm.controls['search_term'].setValue(category.name);
  if(this.configs.title === "ShubhCards")
  {
    
    // this.localService.set('is_category_filter','true')
    // this.localService.set('category_filter_name',category.name)

  } 
  if(this.configs.title === "Deera" || this.configs.title === "BestGifts" || this.configs.title === "Beaubelle"|| this.configs.title === "Kubendran")
  {
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

  }
  if (this.configs.title === 'BombayHardware' || this.configs.title==='AvonSeals') {
    this.localService.set('allProducts','notall')

    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

   }
   let userId = this.localService.get('UserId');
   if (this.configs.title === 'Carience') {
    if(userId){
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    }
    else{
      this.logIn();
     // this.router.navigate(['/login-new'], { queryParams: { productId:product.id, },relativeTo:this.route,  skipLocationChange: false })
    }
   }
   if (this.configs.title === 'Aiema') {
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

   }
   if (this.configs.title === 'Beaubelle') {
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

   }
   if(this.configs.title === "BBold"){
    this.router.navigate([`/product`], {queryParams: { terms:[category.name, 'Category'], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
  } 
  if(this.configs.title === "Mayan"){
    this.router.navigate([`/product`], {queryParams: { terms:[category.name, 'Category'], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });
  } 
  else if(this.configs.title !== 'Aiema' && this.configs.title !== 'Beaubelle' && this.configs.title !== 'BombayHardware' && this.configs.title !== 'AvonSeals' && this.configs.title !== 'BBold' && this.configs.title !== 'Deera' && this.configs.title !== 'BestGifts'&& this.configs.title !== 'Carience'){
    //console.log("buG")
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category.name],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

  }
  this.searchForm.controls['search_term'].setValue('');
		//this.router.navigate([`/productListing`], { queryParams: { terms: [category.name], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
  }
  

  navigateToList1(category) {
    this.searchForm.controls['search_term'].setValue(category);

  if (this.configs.title === 'BombayHardware' || this.configs.title === 'AvonSeals') {
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

  } 
  if (this.configs.title === 'Aiema') {
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

  } 
  if (this.configs.title === 'Beaubelle') {
    // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

  }

	}

  routeTo(){
    document.getElementById("mySidenav").style.width = "0";
  }

  productsList(){
    if(this.configs.title === 'AvonSeals'){
      document.getElementById("mySidenav").style.width = "0";
    }
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
  }

  productsListkubendran(category){
    this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
  
  }
  productsListkubendran1(category){
  if(category.includes('others'))
  {
    document.getElementById(category).style.width = "100%";
  }
  else{
    document.getElementById(category).style.width = "50%";
  }
  document.getElementById("mySidenav").style.width = "0";
    //this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    
  }
  productsListkubendran2(category,categoryname){
    if(category.includes('others'))
    {
      document.getElementById(category).style.width = "100%";
    }
    else{
      document.getElementById(category).style.width = "50%";
    }
    document.getElementById("mySidenav1").style.width = "0";
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[categoryname],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      
    }

  closePrelist(){
    this.searchForm.controls['search_term'].setValue('');
    this.preList = [];
  }

}
