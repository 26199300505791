
<style *ngIf="configs.title != 'Kubendran'"> 
    .sidenav {
   height: 100%;
   width: 0;
   position: fixed;
   top: 0;
   left: 0;
   background-color: white;
   overflow-x: hidden;
   transition: 0.1s;
   padding-top: 60px;
 }
 
 .sidenav a {
   padding: 8px 8px 8px 32px;
   text-decoration: none;
   font-size: 25px;
   color: #818181;
   display: block;
   transition: 0.3s;
 }
 
 .sidenav a:hover {
   color: #f1f1f1;
 }
 
 .sidenav .closebtn {
   position: absolute;
   top: 0;
   right: 25px;
   font-size: 36px;
   margin-left: 50px;
 }
     
     /* .container-fluid-header-sticky-header
     {
         background-color:#2a7d2e;  
     } */
     /* @media (max-width:1100px)  {
         .container-fluid-header-sticky-header
     {
         background-color:#FBCB0C;  
     }
     } */
     @media screen and (min-width:0px) {
         p{
         font-size: 4vw;
     }
         .container-fluid-header-sticky-header{
     display: none;

 }
 .container-fluid-header-sticky-header1{
          display: block;
 }
     
 }
 @media screen and (min-width:1100px) {
     p{
         font-size: 1vw;
     }
     
     .container-fluid-header-sticky-header{
          display: block;
         background-color:white;       
         
 }
 .container-fluid-header-sticky-header1{
          display: none;
 }
 }
   
    </style>
    <style *ngIf="configs.title === 'Kubendran'">
        .sidenav {
       height: 100%;
       width: 0;
       position: fixed;
       top: 0;
       left: 0;
       background-color: #2a7d2e;
       overflow-x: hidden;
       transition: 0.1s;
       padding-top: 60px;
     }
     
     .sidenav a {
       padding: 8px 8px 8px 32px;
       text-decoration: none;
       font-size: 25px;
       color: #818181;
       display: block;
       transition: 0.3s;
     }
     
     .sidenav a:hover {
       color: #f1f1f1;
     }
     
     .sidenav .closebtn {
       position: absolute;
       top: 0;
       right: 25px;
       font-size: 36px;
       margin-left: 50px;
     }
         
         /* .container-fluid-header-sticky-header
         {
             background-color:#2a7d2e;  
         } */
         /* @media (max-width:1100px)  {
             .container-fluid-header-sticky-header
         {
             background-color:#FBCB0C;  
         }
         } */
         @media screen and (min-width:0px) {
             p{
             font-size: 4vw;
         }
             .container-fluid-header-sticky-header{
         display: none;
     }
     .container-fluid-header-sticky-header1{
              display: block;
     }
         
     }
     @media screen and (min-width:1100px) {
         p{
             font-size: 1vw;
         }
         
         .container-fluid-header-sticky-header{
              display: block;
             background-color:#2a7d2e;
            
             
     }
     .container-fluid-header-sticky-header1{
              display: none;
     }
     }

     @media  (max-width:375px) {
         p{
             font-size:4.5vw;
         }
         
         .container-fluid-header-sticky-header{
              display: none;
             background-color:#2a7d2e;
            
             
     }
     .container-fluid-header-sticky-header1{
              display: flex;
     }
     }
       
        </style>
         <div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'Kubendran'">
            <header>
                <div class="row" >
                    <div class="col-1">
                        <img [src]="logoImage" alt="Logo Image" style="padding:18px"class="logo-image custom-pointer" height="80" width="80" alt="Logo"
                        [routerLink]="['/']"> 
                    </div>
                    <div class="col-4"  style="margin-left:25vw"> 
                        <div class="inq-menu-st row justify-content-center">
                            <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                                    data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                                    class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
                                <div id="navbarNav" class="collapse navbar-collapse">
                                    <ul class="navbar-nav" style="padding:20px;">
                                        <li class="nav-item" id="home" style="margin-left: 0.1rem !important"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                href="#/"><p>HOME</p></a></li>
                                                <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsListkubendran('FRUITS')"><p>FRUITS</p></a>
                                                    <app-new-navbarkub  [category]="fruits"></app-new-navbarkub>
                                                    </li>
                                                    <li class="header-cart account-popup1" id="shop" style="margin-left: 2rem !important">
                                                        <a href="javascript:;" class="nav-link menu-text" (click)="productsListkubendran('VEGETABLES')"><p>VEGETABLES</p></a>
                                                        <app-new-navbarkub [category]="vegetables"></app-new-navbarkub>
                                                        </li>
                                        <!-- <li class="header-cart account-popup1" id="shop">
                                            <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                             <app-new-navbar></app-new-navbar>
                                            </li> -->
                                            <!-- <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                <a href="javascript:;" class="nav-link menu-text" (click)="productsListkubendran('OTHERS')"><p>OTHER DEPARTMENTS</p></a>
                                                 <app-new-navbarkub [category]="others"></app-new-navbarkub>
                                                </li> -->
                                                <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                    <a href="#/about-us" class="nav-link menu-text"><p>PAGES</p></a>
                                                     <app-new-navbarkub [category]="pages"></app-new-navbarkub>
                                                    </li>
                            
                                                
                                        <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                                href="#/contact-us">CONTACT US</a></li> -->
                                                
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        </div>
                    <div class="col-3"  style="margin-top: 2%">
                        
                            <ul class="d-flex">
                                <li class="d-flex header-cart account-popup1" style="margin-right: 18%;
                                margin-left: 20%;">
                                    <a href="javascript:;"  style="text-decoration: none !important;color: white !important;" (click)="search()">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </a>
                                    <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                        <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                <div *ngFor="let category of allCategories">
                                                                    <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                </div>                                      
                                                            </div>
                                                          </div>
                                                        <input type="text" placeholder="Search here."
                                                            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                        <div *ngIf="preList.length != 0">
                                                            <div class="autocomplete-items2">
                                                                <div *ngFor="let preItem of preList">
                                                                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="input-group-btn">
                                                            <button class="btn btn-solid" >
                                                                <i class="fa fa-search" (click)="savePreList1()"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                            </form>
                                            
                                        </div>
                                        
                                
                                    </div>
                                </li>
                                
                                <!-- <li>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search">
                                    <div class="input-group-append">
                                      <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                        <i class="fa fa-search"></i>
                                      </button>
                                    </div>
                                  </div>
                                </li> -->
                                
                                <li class="d-flex" style="margin-right: 12%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                    <a href="javascript:;"  style="text-decoration: none !important;color: white !important;">
                                        <span class="fa fa-user" style="font-size:25px">
                                        </span> Login
                                    </a>
                                </li>
        
                                <li class="d-flex" style="margin-right: 3%;" *ngIf="appService.userLoggedIn">
                                    <div class="dropdown">
                                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: white !important;"><span
                                                class="lnr lnr-user">
                                                {{ appService.userName.substring(0,18) }}...
                                            </span>
          <!-- /style="white-space: break-spaces;"                              -->
                                        </a>
                                        <div id="dropdown" class="dropdown-menu" style="background-color: white !important;" aria-labelledby="dropdownMenuButton">
                                            <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #2a7d2e !important;">My Account</a>
                                            <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #2a7d2e !important;">My Order</a>
                                            
                                            <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #2a7d2e !important;">Logout</a>
                                        </div>
                                    </div>
                                </li>
        
                                <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: white !important;">
                                        <span class="fa fa-shopping-basket" style="font-size:25px">
                                            <span style="position: absolute;
                                            background: #FBCB0C;
                                            width: 18px;
                                            height: 18px;
                                            border-radius: 9px;
                                            top: -10px;
                                            right: 9%;" >
                                           
                                                <span style="  color: #fff;
                                                position: absolute;
                                                left: 0.2rem;
                                                font-size: 14px;
                                                font-weight: bold;">{{appService.totalCartItems}}</span>
                                            </span>
                                        </span>  
                                         <!-- <span class="menu-title">
                                            Cart
                                        </span> -->
                                    </a>
                                    <app-mini-cart></app-mini-cart>
                                </li>
                            </ul>
                    
                    </div>
                </div>
                
                    
        
            </header>
        </div>
            <!-- </div> -->
            <div class="container-fluid-header-sticky-header1" style="background-color:#2a7d2e;height: 12vw;
            padding: 2vw;" *ngIf="configs.title === 'Kubendran'">
                <header>
                    <div>
        <ul style="display:flex;gap: 10px;">
            <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:white" (click)="openNavkub()">&#9776;</span>
            </li>
            <li>
                <img [src]="logoImage" alt="Logo Image" style="width: 20vw;
                height: 10vw;
                padding: 1vw;margin-left: 2vw;margin-right:25vw;margin-top: 1px;"class="logo-imagekub custom-pointer" alt="Logo"
                        [routerLink]="['/']"> 
            </li>
            <li class="d-flex header-cart account-popup1">
                                    <a href="javascript:;" (click)="search()">
                                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: white !important;    font-size: 5vw;
                                        margin-left: 1vw;
                                        margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                                    </a>
                                    <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                        <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                <div *ngFor="let category of allCategories">
                                                                    <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                </div>                                      
                                                            </div>
                                                          </div>
                                                       
                                                    </div>
                                            </form>
                                            
                                        </div>
                                        
                                
                                    </div>
                                </li>
                                <li class="d-flex" style="    margin-left: 2vw;
                                margin-top: 1.5vw;margin-right: 2vw;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                    <a href="javascript:;"  style="text-decoration: none !important;color: white !important;">
                                        <span class="fa fa-user" style="font-size:6vw">
                                        </span>
                                    </a>
                                </li>
        
                                <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                                    <div class="dropdown">
                                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: white !important;font-size:3vw;margin-top:1vw"><span
                                                class="lnr lnr-user">
                                            </span>
                                            {{ appService.userName.substring(0,4) }}...
                                            <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                                        </a>
                                        <div id="dropdown" class="dropdown-menu" style="background-color: white !important;" aria-labelledby="dropdownMenuButton">
                                            <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #2a7d2e !important;">My Account</a>
                                            <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #2a7d2e !important;">My Order</a>
                                            
                                            <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #2a7d2e !important;">Logout</a>
                                        </div>
                                    </div>
                                </li>
                                <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: white !important;">
                                        <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                        margin-top: 2vw;
                                        margin-left: 1vw;">
                                            <span style="position: absolute;
                                            background: #FBCB0C;
                                            width: 3vw;
                                            height: 2.5vw;
                                            border-radius: 1vw;" >
                                           
                                                <span style="color: #fff;
                                                position: absolute;
                                                left: 0.3rem;
                                                font-size: 2vw;
                                                font-weight: bold;">{{appService.totalCartItems}}</span>
                                            </span>
                                        </span>  
                                         <!-- <span class="menu-title">
                                            Cart
                                        </span> -->
                                    </a>
                                    <app-mini-cart></app-mini-cart>
                                </li>
        </ul>
        
                    </div>
                    <div id="mySidenav1" class="sidenav">
                        <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNavkub11()"><p>&#8592;</p></a>
                                                <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p>FRUITS &#8594;</p></a>
                        
                        <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p>VEGETABLES &#8594;</p></a>
                        <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myothers1','OTHERS')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->
                                   
                  
                      </div>
                      <div id="myfruits1" class="sidenav">
                        <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;FRUITS </span>
                        
                        <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub>
                    
                      </div>
                      <div id="myvegetables1" class="sidenav">
                        <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;VEGETABLES </span>
                        
                        <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                    
                      </div>
                      <!-- <div id="myothers1" class="sidenav">
                        <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;OTHER DEPARTMENTS </span>
                        
                        <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                    
                      </div> -->
                    <div id="searchnav" class="sidenav">
                        <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closesearchnavkub()"><p>&#8592;</p></a>
                        <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                        <input type="text" placeholder="Search here."
                        class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                        <ul *ngFor="let preItem of preList">
                            <li class="" (click)="savePreList(preItem)" style="color:white;    margin-left: 10px !important;">{{preItem.name}}</li>
                        </ul>
                        </form>
                        
                        <!-- <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                            <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                    <div *ngFor="let category of allCategories">
                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                    </div>                                      
                                                </div>
                                              </div>
                                            <input type="text" placeholder="Search here."
                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                            <div *ngIf="preList.length != 0">
                                                <div class="autocomplete-items2">
                                                    <div *ngFor="let preItem of preList">
                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-btn">
                                                <button class="btn btn-solid" >
                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                </button>
                                            </div>
                                        </div>
                                </form>
                                
                            </div>
                            
                    
                        </div> -->

                    </div>
                    <div id="mySidenav" class="sidenav">
                        <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNavkub1()"><i class="fa fa-arrow-circle-left" style='font-size:35px;position: relative;bottom: 40px;left: 7px;'></i></a>
                        <a class="nav-link menu-text" ng-reflect-router-link="/"
                                                href="#/" (click)="closeNavkub1()"><p>HOME</p></a>
                                                <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')"><p>FRUITS <span style='font-size:16px;'>&#8594;</span></p></a>
                        
                        <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myvegetables')"><p>VEGETABLES <span style='font-size:16px;'>&#8594;</span></p></a>
                        <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myothers')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->
                                   
                  
                      </div>  
                      <div id="myfruits" class="sidenav">
                        <span style="font-size: 6vw;cursor:pointer;color:white;position: relative;left: 1px;" (click)="openNavkub()">FRUITS<i class="fa fa-arrow-circle-left" style='font-size:35px; position: absolute;left: 150px;top:1px;'></i> </span>

                    
                     
                        <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub>
                    
                      </div>
                      <div id="myvegetables" class="sidenav">
                        <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub()">VEGETABLES<i class="fa fa-arrow-circle-left" style='font-size:35px; position: absolute;left: 150px;top: 63px;'></i></span>
                        
                        <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                    
                      </div>
                      <!-- <div id="myothers" class="sidenav">
                        <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub()">&#8592;OTHER DEPARTMENTS </span>
                        
                        <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                    
                      </div> -->
                    
                        
            
                </header>
            </div>

            <div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'AvonSeals'" style="background-color: white !important;">
                <!-- <header>
                    <div class="row" >
                        <div class="col-1" style="margin-left: 450px;transform: translate(80px, 0px);">
                           
                            <img src="../../assets/images/avonseals/Avon Seals.png" alt="Logo Image" style="padding:8px"class="logo-image custom-pointer" height="130" width="130" alt="Logo"
                            [routerLink]="['/']"> 
                        </div>
                        
                        <div class="col-3"  style="margin-top: 2%; margin-left: 250px; transform: translate(160px, 10px);">
                            
                                <ul class="d-flex">
                                    <li class="d-flex header-cart account-popup1" style="margin-right: 18%;
                                    margin-left: 20%;">
                                        <a href="javascript:;"  style="text-decoration: none !important;color: black !important;" (click)="search()">
                                            <i class="fa fa-search" aria-hidden="true"></i>
                                        </a>
                                        <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                            <div class="row" style="border-bottom : 2px dashed black;margin: 5px;">
                                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                    <div *ngFor="let category of allCategories">
                                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                    </div>                                      
                                                                </div>
                                                              </div>
                                                            <input type="text" placeholder="Search here."
                                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                            <div *ngIf="preList.length != 0">
                                                                <div class="autocomplete-items2">
                                                                    <div *ngFor="let preItem of preList">
                                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="input-group-btn">
                                                                <button class="btn btn-solid" >
                                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                </form>
                                                
                                            </div>
                                            
                                    
                                        </div>
                                    </li>
                                    
                                
                                    
                                    <li class="d-flex" style="margin-right: 12%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                        <a href="javascript:;"  style="text-decoration: none !important;color: black !important;">
                                            <span class="fa fa-user" style="font-size:25px">
                                            </span> Login
                                        </a>
                                    </li>
            
                                    <li class="d-flex" style="margin-right: 3%;" *ngIf="appService.userLoggedIn">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: white !important;"><span
                                                    class="lnr lnr-user">
                                                    {{ appService.userName.substring(0,18) }}...
                                                </span>
             
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" style="background-color: black!important;" aria-labelledby="dropdownMenuButton">
                                                <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: black !important;">My Account</a>
                                                <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: black!important;">My Order</a>
                                                
                                                <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: black !important;">Logout</a>
                                            </div>
                                        </div>
                                    </li>
            
                                    <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                        <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: black !important;">
                                            <span class="fa fa-shopping-basket" style="font-size:25px">
                                                <span style="position: absolute;
                                                background: #FBCB0C;
                                                width: 18px;
                                                height: 18px;
                                                border-radius: 9px;
                                                top: -10px;
                                                right: 9%;" >
                                               
                                                    <span style="  color: #fff;
                                                    position: absolute;
                                                    left: 0.2rem;
                                                    font-size: 14px;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                                                </span>
                                            </span>  
                                          
                                        </a>
                                        <app-mini-cart></app-mini-cart>
                                    </li>
                                </ul>
                        
                        </div>
                    </div>
                    
                        
            
                </header> -->
                <header>
                    <div class="row" style="display: inline-flex; justify-content: normal; width: 100%;">
                        <div class="col-1" style="margin-left: 44%;margin-right: 23%;">
                            
                            <img src="../../assets/images/avonseals/Avon Seals.png" alt="Logo Image" style="padding:8px; object-fit: contain;"class="logo-image custom-pointer" height="140" width="185" alt="Logo"
                            [routerLink]="['/']"> 
                        </div>
                        
                        <div class="col-3"  style="margin-top: 5%;margin-right:inherit;">
                            
                                <ul class="d-flex">
                                    <li class="d-flex header-cart account-popup1" style="margin-right: 20%;
                                    margin-left: 20%;">
                                        <a href="javascript:;"  style="text-decoration: none !important;color: black !important;" (click)="search()">
                                            <i class="fa fa-search" aria-hidden="true"></i>
                                        </a>
                                        <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;margin-top: -30px;">
                                            <div class="row" style="border-bottom : 2px;margin: 5px;">
                                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                    <div *ngFor="let category of allCategories">
                                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                    </div>                                      
                                                                </div>
                                                              </div>
                                                            <input type="text" placeholder="Search here."
                                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                            <div *ngIf="preList.length != 0">
                                                                <div class="autocomplete-items2">
                                                                    <div *ngFor="let preItem of preList">
                                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="input-group-btn">
                                                                <button class="btn btn-solid" >
                                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                </form>
                                                
                                            </div>
                                            
                                    
                                        </div>
                                    </li>
                                    
                                    <!-- <li>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search">
                                        <div class="input-group-append">
                                          <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                            <i class="fa fa-search"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </li> -->
                                    
                                    <li class="d-flex" style="margin-right: 25%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                        <a href="javascript:;"  style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">
                                            <span class="fa fa-user" style="font-size:25px">
                                            </span> Login
                                        </a>
                                    </li>
            
                                    <li class="d-flex" style="margin-right: 3%;" *ngIf="appService.userLoggedIn">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: #1d1b1a !important;"><span
                                                    class="lnr lnr-user">
                                                    {{ appService.userName.substring(0,18) }}...
                                                </span>
              <!-- /style="white-space: break-spaces;"                              -->
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" style="background-color: white!important;" aria-labelledby="dropdownMenuButton">
                                                <a class="logout-cutom dropdown-item" routerLink="my-account" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My Account</a>
                                                <a class="logout-cutom dropdown-item" routerLink="my-orders" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My Order</a>
                                                
                                                <a class="logout-cutom dropdown-item" (click)="logOut()" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">Logout</a>
                                            </div>
                                        </div>
                                    </li>
            
                                    <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                        <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: black !important;">
                                            <span class="fa fa-shopping-basket" style="font-size:25px">
                                                <span style="position: absolute;
                                                background: #FBCB0C;
                                                width: 18px;
                                                height: 18px;
                                                border-radius: 9px;
                                                top: -10px;
                                                right: 9%;" >
                                               
                                                    <span style="  color: #fff;
                                                    position: absolute;
                                                    left: 0.2rem;
                                                    font-size: 14px;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                                                </span>
                                            </span>  
                                             <!-- <span class="menu-title">
                                                Cart
                                            </span> -->
                                        </a>
                                        <app-mini-cart></app-mini-cart>
                                    </li>
                                </ul>
                        
                        </div>
                    </div>
                    
                        
            
                </header>
                <hr style="width:700px ;border: 1px solid rgb(27, 26, 26); margin-left: auto; margin-right: auto; margin-top: -8px;" >
                <header>
                    <div class="row2" style="height: 71px;">
                        <div class="col-4"  style="margin-left: 33vw; height: 50px; margin-top: -15px; margin-bottom: 15px;"> 
                            <div class="inq-menu-st row justify-content-center">
                                <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                                        data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                                        class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
                                    <div id="navbarNav" class="collapse navbar-collapse">
                                        <!-- <ul class="navbar-nav" style="padding:20px;">
                                            <li class="nav-item" id="home" style="margin-left: 0.1rem !important"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                    href="#/"><p style="color:black">HOME</p></a></li>
                                                    <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                        <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p style="color:black">PRODUCTS</p></a>
                                                      
                                                        </li>
                                                        <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                            <a href="javascript:;" class="nav-link menu-text" ><p style="color:black">ABOUTUS</p></a>
                                                           
                                                            </li>
                                           
                                                    <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                        <a href="#/about-us" class="nav-link menu-text"><p style="color:black">CONTACTUS</p></a>
                                                       
                                                        </li>
                                
                                                    
                                         
                                                    
                                        </ul> -->
                                        <ul class="navbar-nav" style="padding:20px;">
                                            <li class="nav-item" id="home" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                    href="#/"><p style="font-weight: bold;color:black">HOME</p></a></li>
                                                    <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                        <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important; font-weight: bold;color:black">PRODUCTS</p></a>
                                                        <!-- <app-new-navbarkub [category]="fruits"></app-new-navbarkub> -->
                                                        </li>
                                                        <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                                            <a href="javascript:;" class="nav-link menu-text"href="#/about-us" ><p style="font-weight: bold;color:black">ABOUTUS</p></a>
                                                            <!-- <app-new-navbarkub [category]="vegetables"></app-new-navbarkub> -->
                                                            </li>
                                            <!-- <li class="header-cart account-popup1" id="shop">
                                                <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                                 <app-new-navbar></app-new-navbar>
                                                </li> -->
                                                <!-- <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsListkubendran('OTHERS')"><p>OTHER DEPARTMENTS</p></a>
                                                     <app-new-navbarkub [category]="others"></app-new-navbarkub>
                                                    </li> -->
                                                    <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                                        <a href="#/contact-us" class="nav-link menu-text"><p style="font-weight: bold;color:black">CONTACTUS</p></a>
                                                         <!-- <app-new-navbarkub [category]="pages"></app-new-navbarkub> -->
                                                        </li>
                                
                                                    
                                            <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                                    href="#/contact-us">CONTACT US</a></li> -->
                                                    
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            </div>
                    </div>
                </header>
            </div>

<div class="container-fluid-header-sticky-header1" style="background-color:white;height: 12vw;
                padding: 2vw;" *ngIf="configs.title === 'AvonSeals'">
                    <header>
                        <div>
            <ul style="display:flex;gap: 0px;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black" (click)="openNavkub()">&#9776;</span>
                </li>
                <li>
                    <!-- <img [src]="logoImage" alt="Logo Image" style="width: 20vw;
                    height: 10vw;
                    padding: 1vw;margin-left: 2vw;margin-right:25vw;margin-top: 1px;"class="logo-imagekub custom-pointer" alt="Logo"
                            [routerLink]="['/']">  -->
                            <img src="../../assets/images/avonseals/Avon Seals.png" alt="Logo Image" style="width: 20vw;
                            height: 15vw;
                            ;margin-left: 25vw;margin-right:15vw;"class="logo-imagekub custom-pointer" alt="Logo"
                                    [routerLink]="['/']"> 
                </li>
                <li class="d-flex header-cart account-popup1">
                                        <a href="javascript:;" (click)="search()">
                                            <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                                            margin-left: 1vw;
                                            margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                                        </a>
                                        <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                            <div class="row" style="border-bottom : 2px;margin: 5px;">
                                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                    <div *ngFor="let category of allCategories">
                                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                    </div>                                      
                                                                </div>
                                                              </div>
                                                           
                                                        </div>
                                                </form>
                                                
                                            </div>
                                            
                                    
                                        </div>
                                    </li>
                                    <li class="d-flex" style="    margin-left: 2vw;
                                    margin-top: 1.5vw;margin-right: 2vw;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                        <a href="javascript:;"  style="text-decoration: none !important;color: black !important;">
                                            <span class="fa fa-user" style="font-size:6vw">
                                            </span>
                                        </a>
                                    </li>
            
                                    <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:1vw"><span
                                                    class="lnr lnr-user">
                                                </span>
                                                {{ appService.userName.substring(0,4) }}...
                                                <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" style="background-color: white !important;" aria-labelledby="dropdownMenuButton">
                                                <div id="dropdown" class="dropdown-menu show" style=" transform: translate3d(-47px, -20px, 0px) !important;">
                                                <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: black !important;">My Account</a>
                                                <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: black !important;">My Order</a>
                                                
                                                <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: black !important;">Logout</a>
                                            </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                        <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: black !important;">
                                            <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                            margin-top: 2vw;
                                            margin-left: 1vw;">
                                                <span style="position: absolute;
                                                background: #FBCB0C;
                                                width: 3vw;
                                                height: 2.5vw;
                                                border-radius: 1vw;" >
                                               
                                                    <span style="color: #fff;
                                                    position: absolute;
                                                    left: 0.3rem;
                                                    font-size: 2vw;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                                                </span>
                                            </span>  
                                             <!-- <span class="menu-title">
                                                Cart
                                            </span> -->
                                        </a>
                                        <app-mini-cart></app-mini-cart>
                                    </li>
            </ul>
            
                        </div>
                        <div id="mySidenav1" class="sidenav" style="background-color:#fff">
                            <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text" (click)="closeNavkub11()"><p>&#8592;</p></a>
                                                    <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p style="color:black">HOME&#8594;</p></a>
                            
                            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">PRODUCTS &#8594;</p></a>
                            <a href="javascript:void(0)"  style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">ABOUTUS &#8594;</p></a>
                            <a href="javascript:void(0)"  style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">CONTACTUS &#8594;</p></a>
                            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myothers1','OTHERS')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->
                                       
                      
                          </div>
                          <div id="myfruits1" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>
                            
                            <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub>
                        
                          </div>
                          <div id="myvegetables1" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;PRODUCTS </span>
                            
                            <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                        
                          </div>
                          <div id="myvegetables1" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>
                            
                            <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                        
                          </div>
                          <div id="myvegetables1" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>
                            
                            <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                        
                          </div>
                          <!-- <div id="myothers1" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;OTHER DEPARTMENTS </span>
                            
                            <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                        
                          </div> -->
                        <div id="searchnav" class="sidenav" style="background-color:#fff">
                            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closesearchnavkub()"><p>&#8592;</p></a>
                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                            <input type="text" placeholder="Search here."
                            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                            <ul *ngFor="let preItem of preList">
                                <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">{{preItem.name}}</li>
                            </ul>
                            </form>
                            
                            <!-- <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                    <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                        <div *ngFor="let category of allCategories">
                                                            <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                        </div>                                      
                                                    </div>
                                                  </div>
                                                <input type="text" placeholder="Search here."
                                                    class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                <div *ngIf="preList.length != 0">
                                                    <div class="autocomplete-items2">
                                                        <div *ngFor="let preItem of preList">
                                                            <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="input-group-btn">
                                                    <button class="btn btn-solid" >
                                                        <i class="fa fa-search" (click)="savePreList1()"></i>
                                                    </button>
                                                </div>
                                            </div>
                                    </form>
                                    
                                </div>
                                
                        
                            </div> -->
    
                        </div>
                        <div id="mySidenav" class="sidenav" style="background-color:#fff">
                            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNavkub1()"><p>&#8592;</p></a>
                            <a class="nav-link menu-text" ng-reflect-router-link="/"
                                                    href="#/" (click)="closeNavkub1()"><p style="color:black">HOME</p></a>
                                                    <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')"><p>HOME &#8594;</p></a> -->
                            
                            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsList()"><p style="color:black">PRODUCTS</p></a>
                            <a href="javascript:void(0)" class="nav-link menu-text" href="#/about-us" (click)="routeTo()"><p style="color:black">ABOUT US</p></a>
                            <a href="javascript:void(0)" class="nav-link menu-text" href="#/contact-us" (click)="routeTo()"><p style="color:black">CONTACT US</p></a>
                            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myothers')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->
                                       
                      
                          </div>  
                          <div id="myfruits" class="sidenav" style="background-color:#fff">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>
                            
                            <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->
                        
                          </div>
                          <div id="myvegetables" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">PRODUCTS </span>
                            
                            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->
                        
                          </div>
                          <div id="myvegetables" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>
                            
                            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->
                        
                          </div>
                          <div id="myvegetables" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>
                            
                            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->
                        
                          </div>
                          <!-- <div id="myothers" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub()">&#8592;OTHER DEPARTMENTS </span>
                            
                            <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                        
                          </div> -->
                        
                            
                
                    </header>
                </div>
    <!-- <div class="earthy-worthy-section earthy-worthy-menu"> -->
        <div class="container-fluid header sticky-header vimpro-header" *ngIf="configs.title != 'Aiema'&& configs.title != 'BestGifts' && configs.title != 'AvonSeals'">
            <header>
                <div class="inq-htop-st inq-row" id="banner" *ngIf="promotionBanner != '' && configs.title === 'Deera'">
                    <div class="text-center">
                        <p>
                            <span [innerHtml]="promotionBanner"></span>
                            <!-- <span>
                                <a href="#"><strong> More Details</strong></a>
                            </span> -->
                        </p>
                        <!-- <p>Get <strong>UPTO 40% OFF</strong> on your 1st order <span><a href="#"><strong> More Details</strong>
                                </a></span></p> -->
                        <span style="cursor: pointer;"><i class="fa fa-close" (click)='hideBanner()'></i></span>
                    </div>
                </div>
                <div class="flex-container" *ngIf="configs.title != 'BestGifts'&& configs.title != 'Kubendran'">
                    <img [src]="logoImage" alt="Logo Image" class="logo-image custom-pointer mob-logo" height="50" alt="Logo" [routerLink]="['/']">
                    <div class="header-row-one">
                        <div class="search-bar-group" style="margin-right: 10px !important; margin-top: 5px;">
                            <form [formGroup]="searchForm" class="search-bar">
                                <div class="d-flex justify-content-between search-input">


                                    <!-- <p>Browse all <br><span>Categories</span></p> -->
                                    <div class="input-group">
                                        <div class="input-group-prepend" *ngIf="configs.title != 'Vimpro'" >
                                            <button class="btn search-drop dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="closePrelist()" style="font-size: 14px !important;">All Categories</button>
                                            <div class="dropdown-menu autocomplete-items">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                </div>                                      
                                            </div>
                                          </div>
                                        

                                        <div  id='vimpro_cate' class="input-group-prepend desktop-only"  *ngIf="configs.title == 'Vimpro'" style="    position: fixed!important;">
                                            <button class="btn search-drop dropdown-toggle v_cate_top"  style="min-height: 42px;"  type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="closePrelist()" >Top Categories</button>
                                            <div class="dropdown-menu autocomplete-items vim_dropdown" style="background-color: rgb(37 84 199);;width: 15rem!important">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;color: white!important;">{{category.name | titlecase}}</a>
                                                </div>                                      
                                            </div>
                                          </div>
                                          
                                        <input type="text" placeholder="Search here.." *ngIf="configs.title !== 'Vimpro'"
                                            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                        <input type="text" placeholder="Search our catalogue.." *ngIf="configs.title === 'Vimpro'" style="border-radius: 50px 0px 0px 50px !important;"
                                            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">

                                        <div *ngIf=" configs.title != 'Vimpro' && preList.length != 0">
                                            <div class="autocomplete-items1">
                                                <div *ngFor="let preItem of preList">
                                                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf=" configs.title == 'Vimpro' && preList.length != 0">
                                            <div class="autocomplete-items3">
                                                <div *ngFor="let preItem of preList">
                                                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-btn">
                                            <button class="btn btn-solid vimpro-searchbtn" >
                                                <i class="fa fa-search" (click)="savePreList1()"></i>
                                            </button>
                                        </div> 
                                    </div>
                                    
                                </div>
                            </form>   
                            <ul class="account mobile-cart" style="margin-top:-40px;margin-bottom:0px !important;margin-left:10px">            
                                <li class="header-cart account-popup" style="margin-right:-17px">                                        
                                    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;">
                                        <span class="fa fa-shopping-cart white_vimpro" style="font-size:25px;">
                                            <span class="count">
                                                <span class="count-number">{{appService.totalCartItems}}</span>
                                            </span>
                                        </span>  
                                         <span class="menu-title white_vimpro" >
                                            Cart
                                        </span>
                                    </a>
                                    <app-mini-cart></app-mini-cart>
                                </li>
                            </ul>                         
                        </div>
                        <div class="account-section">
                            <ul class="account">
                                
                                <li *ngIf="!appService.userLoggedIn" (click)="logIn()" style="margin-right:10px !important">
                                    <a  *ngIf="configs.title!='Vimpro'" href="javascript:;" >
                                        <span class="fa fa-user">
                                        </span> Login
                                    </a>
                                    <a  *ngIf="configs.title=='Vimpro'" href="javascript:;" style="color:white!important" >
                                        <span class="fa fa-user" style="color:white">
                                        </span> Login
                                    </a>
                                </li>
        
                                <li *ngIf="appService.userLoggedIn">
                                    <div class="dropdown">
                                        <a  *ngIf="configs.title!='Vimpro'"  class="menu-title dropdown-toggle " type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                                                class="lnr lnr-user loginbut">
                                            </span>
                                            {{ appService.userName }}
                                        </a>
                                        <a  *ngIf="configs.title=='Vimpro'"  class="menu-title dropdown-toggle loginicon" type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                                                class="lnr lnr-user loginbut">
                                            </span>
                                            {{ appService.userName }}
                                        </a>
                                        <div id="dropdown" class="dropdown-menu l-5px" aria-labelledby="dropdownMenuButton">
                                            <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                            <a *ngIf= "configs.title !== 'BombayHardware' && configs.title !== 'AvonSeals'" class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a>
                                            <a *ngIf= "configs.title === 'BombayHardware' || configs.title === 'AvonSeals'" class="logout-cutom dropdown-item" routerLink="enquiry-status">My Enquiry</a>

                                            <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                                        </div>
                                    </div> 
                                </li>
        
                                <li class="header-cart account-popup desktop-only">     
                                    <a *ngIf="configs.title=='Vimpro'"  href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: white!important;">
                                        <span class="fa fa-shopping-cart" style="font-size:25px">
                                            <span class="count">
                                                <span class="count-number">{{appService.totalCartItems}}</span>
                                            </span>
                                        </span>  
                                         <span class="menu-title" style="color: white!important;">
                                            Cart
                                        </span>
                                    </a>                                   
                                    <a  *ngIf="configs.title!='Vimpro'" href="javascript:;" routerLink="/cart" style="text-decoration: none !important;">
                                        <span class="fa fa-shopping-cart" style="font-size:25px">
                                            <span class="count">
                                                <span class="count-number">{{appService.totalCartItems}}</span>
                                            </span>
                                        </span>  
                                         <span class="menu-title">
                                            Cart
                                        </span>
                                    </a>
                                    <app-mini-cart></app-mini-cart>
                                </li>
                            </ul>
                        </div>
        
                    </div>
        
                </div>
        
            </header>
        </div>
        <div class="container-fluid navbar-row vimpro-nav" *ngIf="configs.title != 'Aiema' && configs.title != 'BestGifts' && configs.title != 'Kubendran'">
            <app-menu></app-menu>
        </div>
    <!-- </div> -->
    <div *ngIf="configs.title === 'Aiema'">
        <div class="earthy-worthy-section earthy-worthy-menu">
            <div class="container-fluid header sticky-header" >
                <header>
                    <div class="flex-container">
                        <img [src]="logoImage" alt="Logo Image" class="custom-pointer aiema-logo" height="50" alt="Logo" style="width: 72px!important; height: 63px!important;"
                                    [routerLink]="['/']">
                        <div class="header-row-one" style="width: 100%;">
                            <div class="search-bar-group" style="margin-right: 10px !important;display:contents;z-index:10; width:100%;margin-top: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style=" width:100%;margin-top:15px">
                                    <div class="d-flex justify-content-between search-input-aiema" style="margin-right:25px;">
                                        <!-- <p>Browse all <br><span>Categories</span></p> -->
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <button class="btn search-drop dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="closePrelist()" style="font-size: 14px !important;">All Categories</button>
                                                <div class="dropdown-menu autocomplete-items">
                                                    <div *ngFor="let category of allCategories">
                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                    </div>                                      
                                                </div>
                                              </div>
                                            <input type="text" placeholder="Search here.."
                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                            <div *ngIf="preList.length != 0">
                                                <div class="autocomplete-items1">
                                                    <div *ngFor="let preItem of preList">
                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-btn">
                                                <button class="btn btn-solid" >
                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="account-section">
                                <ul class="account" style="margin-right:0px">
                                    <li *ngIf="!appService.userLoggedIn" style="margin-left:20px !important">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton1"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;font-size: 14px;font-weight: 500;"><span
                                                class="fa fa-user" style="font-size:25px; color: rgb(102, 102, 102);">
                                                </span>
                                                User/Member Login
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <a class="logout-cutom dropdown-item" routerLink="login-new">User Login/Register</a>
                                                <a class="logout-cutom dropdown-item" routerLink="member-register">Member Register</a>
                                            </div>
                                        </div>
                                    </li>
            
                                    <li *ngIf="appService.userLoggedIn" style="margin-left:20px !important">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;"><span
                                                    class="lnr lnr-user">
                                                </span>
                                                {{ appService.userName }}
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                                <!-- <a class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a> -->
                                                <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <!-- <div class="float-right">
                                <button data-toggle="tooltip" data-placement="top" title="Upcoming Feature" class="btn btn-outline-warning shadow-sm" style="border-radius: 30px !important; font-size: 14px; padding: 5px 25px;font-weight: 500;" disabled>Quick Enquiry</button>
                            </div> -->
                        </div>
            
                    </div>
            
                </header>
            </div>
            <div class="container-fluid navbar-row" style="padding-left: 0px !important;padding-right: 0px !important;">
                <app-menu></app-menu>
            </div>
        </div>
    </div>
<!-- </div> -->

<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'BestGifts'" style="background-color:#fff;height: 5vw;">
    <header>
        <div class="row header_bestgift" >
            <div class="col-1">
                <img [src]="logoImage" alt="Logo Image" style="margin-left: 40px;"class="logo-image custom-pointer" height="80" width="80" alt="Logo"
                [routerLink]="['/']"> 
            </div>
            <div class="col-6" *ngIf="configs.title === 'BestGifts'" style="margin-left:auto"> 
                <div class="inq-menu-st row justify-content-center">
                    <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                            class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
                        <div id="navbarNav" class="collapse navbar-collapse">
                            <ul class="navbar-nav" style="padding:20px">
                                <li class="nav-item" id="home"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                        href="#/" style="width: max-content!important;"><p>HOME</p></a></li>
                                        <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                            <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>SHOP</p></a>
                                            <app-new-navbar [category]="fruits"></app-new-navbar>
                                            </li>
                                            <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                                <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>BESTGIFTS</p></a> -->
                                                <!-- <app-new-navbar [category]="vegetables"></app-new-navbar> -->
                                                <!-- </li> -->
                                <!-- <li class="header-cart account-popup1" id="shop">
                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                     <app-new-navbar></app-new-navbar>
                                    </li> -->
                                    <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                        <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>VALENTINE SPECIAL</p></a> -->
                                         <!-- <app-new-navbar [category]="others"></app-new-navbar> -->
                                        <!-- </li> -->
                                        <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                            <a href="#/about-us" class="nav-link menu-text"><p>ABOUT US</p></a>
                                            </li>
                                            <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                                <a href="#/contact-us" class="nav-link menu-text"><p>CONTACT US</p></a>
                                                </li>
                                        
                                <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                        href="#/contact-us">CONTACT US</a></li> -->
                                        
                            </ul>
                        </div>
                    </nav>
                </div>
                </div>
            <!-- <div class="col-3" style="margin-top: 2%"> -->
                <div class="col-3" style="margin-top: 2%">
                
                    <ul class="d-flex">
                        <li class="d-flex header-cart account-popup1" style="margin-right: 18%;
                        margin-left: 20%;">
                            <a href="javascript:;"  style="text-decoration: none !important;color:#3C57A6 !important;" (click)="search()">
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </a>
                            <div class="min-cart-product-wrapper popup-content2 " style="height:auto;overflow-y:visible!important;">
                                <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                    <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                        <div *ngFor="let category of allCategories">
                                                            <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                        </div>                                      
                                                    </div>
                                                  </div>
                                                <input type="text" placeholder="Search here."
                                                    class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                <div *ngIf="preList.length != 0">
                                                    <!-- <div class="autocomplete-items2"> -->
                                                        <div class="autocomplete-items2">
                                                        <div *ngFor="let preItem of preList">
                                                            <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="input-group-btn">
                                                    <button class="btn btn-solid" >
                                                        <i class="fa fa-search" (click)="savePreList1()"></i>
                                                    </button>
                                                </div>
                                            </div>
                                    </form>
                                    
                                </div>
                                
                        
                            </div>
                        </li>
                        
                        <!-- <li>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <div class="input-group-append">
                              <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                <i class="fa fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </li> -->
                        
                        <li class="d-flex" style="margin-right: 12%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                            <a href="javascript:;"  style="text-decoration: none !important;color: #3C57A6 !important;">
                                <span class="fa fa-user" style="font-size:25px">
                                </span> Login
                            </a>
                        </li>

                        <li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 0px;">
                            <div class="dropdown">
                                <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: #3C57A6 !important;"><span
                                        class="lnr lnr-user">
                                    </span>
                                    {{ appService.userName }}
                                </a>
                                <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #3C57A6 !important;">My Account</a>
                                    <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #3C57A6!important;">My Order</a>
                                    
                                    <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #3C57A6!important;">Logout</a>
                                </div>
                            </div>
                        </li>

                        <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                            <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: #3C57A6!important;">
                                <span class="fa fa-shopping-basket" style="font-size:25px">
                                    <span style="position: absolute;
                                    background: #FBCB0C;
                                    width: 18px;
                                    height: 18px;
                                    border-radius: 9px;
                                    top: -10px;
                                    right: 9%;" >
                                   
                                        <span style="  color: #fff;
                                        position: absolute;
                                        left: 0.2rem;
                                        font-size: 14px;
                                        font-weight: bold;">{{appService.totalCartItems}}</span>
                                    </span>
                                </span>  
                                 <!-- <span class="menu-title">
                                    Cart
                                </span> -->
                            </a>
                            <app-mini-cart></app-mini-cart>
                        </li>
                    </ul>
            
            </div>
        </div>
        
            

    </header>
</div>


<div class="container-fluid-header-sticky-header1" style="background-color:white;height: 12vw;
padding: 2vw;border: 2px solid rgba(0,0,0,.1)!important;" *ngIf="configs.title === 'BestGifts'">
    <header>
        <div>
<ul style="display:flex">
<li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:#3C57A6" (click)="openNav()">&#9776;</span>
</li>
<li style="margin-left: 6px;">
    <div class="row">
        <span>
            <img [src]="logoImage" alt="Logo Image" style="    width: 20vw;
            height: 10vw;
            padding: 1vw;
            margin-left: 2vw;
            margin-right: 77px;
            margin-top: 1px;"class="logo-image custom-pointer" alt="Logo"
                    [routerLink]="['/']"> 
        </span>
    </div>   
</li>
<li class="d-flex header-cart account-popup1">
                        <a href="javascript:;" (click)="search()">
                            <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: #3C57A6 !important;    font-size: 5vw;
                            margin-left: 1vw;
                            margin-top: 2vw;"></i>
                        </a>
                        <div class="min-cart-product-wrapper popup-content2 " style="height:auto;overflow-y:visible!important;">
                            <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                    <div *ngFor="let category of allCategories">
                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                    </div>                                      
                                                </div>
                                              </div>
                                            <input type="text" placeholder="Search here."
                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                            <div *ngIf="preList.length != 0">
                                                <div class="autocomplete-items2">
                                                    <div *ngFor="let preItem of preList">
                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-btn">
                                                <button class="btn btn-solid" >
                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                </button>
                                            </div>
                                        </div>
                                </form>
                                
                            </div>
                            
                    
                        </div>
                    </li>
                    <li class="d-flex" style="    margin-left: 4vw;
                    margin-top: 1.5vw;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                        <a href="javascript:;"  style="text-decoration: none !important;color: #3C57A6 !important">
                            <span class="fa fa-user" style="font-size:6vw">
                            </span>
                        </a>
                    </li>

                    <li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 0px;">
                        <div class="dropdown">
                            <a class="menu-title dropdown-toggle profile_name" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: #3C57A6 !important;"><span
                                    class="lnr lnr-user">
                                </span>
                                {{ appService.userName }}
                            </a>
                            <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #3C57A6 !important;">My Account</a>
                                <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #3C57A6 !important;">My Order</a>
                                
                                <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #3C57A6 !important;">Logout</a>
                            </div>
                        </div>
                    </li>
                    <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                        <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: #3C57A6 !important;">
                            <span class="fa fa-shopping-basket" style="font-size: 5vw;
                            margin-top: 2vw;
                            margin-left: 4vw;">
                                <span style="position: absolute;
                                background: #FBCB0C;
                                width: 3vw;
                                height: 2.5vw;
                                border-radius: 1vw;" >
                               
                                    <span style="color: #fff;
                                    position: absolute;
                                    left: 0.3rem;
                                    font-size: 2vw;
                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                                </span>
                            </span>  
                             <!-- <span class="menu-title">
                                Cart
                            </span> -->
                        </a>
                        <app-mini-cart></app-mini-cart>
                    </li>
</ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNav1()"><p>&#8592;</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p>SHOP &#8594;</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p>BESTGIFTS &#8594;</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p>VALETINE SPECIAL &#8594;</p></a>
                    
      
          </div>
          <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;SHOP </span>
            
            <app-new-navbar  [category]="fruitsres"></app-new-navbar>
        
          </div>
          <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;BESTGIFTS </span>
            
            <app-new-navbar  [category]="vegetablesres"></app-new-navbar>
        
          </div>
          <div id="myothers1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;VALETINE SPECIAL </span>
            
            <app-new-navbar  [category]="othersres"></app-new-navbar>
        
          </div>
        <div id="mySidenav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNav()"><p>&#8592;</p></a>
            <a class="nav-link menu-text" ng-reflect-router-link="/"
                                    href="#/" (click)="closeNav()"><p>HOME</p></a>
                                    <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')"><p>SHOP &#8594;</p></a>
            
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="bestgifts()"><p>BESTGIFTS</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="bestgifts()"><p>VALETINE SPECIAL</p></a>
                       
      
          </div>  
          <div id="myfruits" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;SHOP </span>
            
            <app-new-navbar  [category]="fruitsres"></app-new-navbar>
        
          </div>
          <div id="myvegetables" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;BESTGIFTS </span>
            
            <app-new-navbar  [category]="vegetablesres"></app-new-navbar>
        
          </div>
          <div id="myothers" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;VALETINE SPECIAL </span>
            
            <app-new-navbar  [category]="othersres"></app-new-navbar>
        
          </div>
        
            

    </header>
</div>

    <!-- </div> -->
