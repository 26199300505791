import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HeaderService } from '../../header/Header/header.service';
import metaData from 'src/assets/data/default/header-footer.json';
import metaData_vimpro from 'src/assets/data/vimpro/header-footer.json';
import { config }  from 'src/configs/config';
import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';
import { UntypedFormGroup ,FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-two-footer',
  templateUrl: './footer-two.component.html'
})
export class FooterTwoComponent implements OnInit {
  subscribeForm: FormGroup;
  formBuilder: any;
  footerService: any;
  validateForm: boolean = false;
  showalertmsg: boolean = false;
  //appService: any;
  alertService: any;
  windowscroll()
  {
    window.scrollTo(0,0)
  }
  config=config;

  copyRightText:any = "";

  onLineShopping = metaData.footer;
  onLineShopping_vimpro = metaData_vimpro.footer;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private headerService: HeaderService,
    private localService: LocalStorageService,
    private formbuilder: FormBuilder,
     private appService : AppService
  ) { }

  ngOnInit(): void {
    // this.getCopyRightText();
    this.subscribeForm = new FormGroup({
      email: this.formbuilder.control('', [Validators.required, Validators.maxLength(50), Validators.email, Validators.pattern("^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$")]),
    })
    this.subscribeForm.controls.email.setValue('');
    // this.footerService.currentData.subscribe(
    //   currentData => {
    //     if (currentData === 'clearSearchTerm') {
    //       this.subscribeForm.controls.email.setValue('');
    //       this.validateForm=false;
    //     }
    //   }
    // )
  }
  get f() { return this.subscribeForm.controls; }
  subscribe() {
    this.showalertmsg = true;
    if (this.subscribeForm.valid) {
      this.validateForm = false;
    var subscribeFormData = {
      domain_name: ORG_DETAILS.DOMAIN_NAME,
      user_id: ORG_DETAILS.USER_ID,
      "payload": {
        "customerSubcribe": {
          "has_also_subscribed": true,
          "email": this.subscribeForm.value.email
        }
      },
      "extras": {
        "find": {
          "email": this.subscribeForm.value.email
        }
      }

    }
console.log(subscribeFormData)
    this.appService.postApiCall(subscribeFormData, ENDPOINTS.SUBSCRIBE).subscribe(resp => {
      if (resp) {
        if (resp.success == 1) {
          this.alertService.success('Subscribed Succesfully!', this.options);
          window.scroll(0,0);
          this.subscribeForm.reset();
          
        }
        else {
          this.alertService.warn(resp.message, this.options);
        }
      }
    },
      err => {
        if (err.error.message) {
          this.alertService.error(err.error.message, this.options);
        }
        else {
          this.alertService.error('Something bad happened. Please try again!', this.options);
        }
      }
    )
    // }
    // if (this.subscribeForm.valid) {
    //   //this.validateForm = false;

      
    }
    else {
      this.validateForm = true;
    }

  }
  options(arg0: string, options: any) {
    throw new Error('Method not implemented.');
  }

  navigateToProduct(link){
    this.headerService.updateAdminFormEvent('clearSearchTerm');
    console.log("link",link)
    window.scrollTo({top: 0, behavior: 'smooth'});
    // this.router.navigate([`/productListing`], {queryParams: { terms:link, type:'filter' }, relativeTo:this.route,  skipLocationChange: false });
   if(config.title==='BombayHardware' || config.title==='AvonSeals'){
    this.localService.set('allProducts','notall')
    if(link == '/Pipes'){
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],['Pipes'],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });	
    }
    if(link == '/Supreme Pipes & Fittings'){
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],['Supreme Pipes and Fittings'],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });	
    }
   } else if(config.title === "BBold"){
    this.router.navigate([`/product`], {queryParams: { }, relativeTo:this.route,  skipLocationChange: false });
  } else {
    this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });

   }

   
    // if(link==='/productListing') {
    //   this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });

    // } 
    // if(link==='/category-listing'){
    //   this.router.navigate([`/category-listing`]);

    // }

  }

  scrollFunction(){
    window.scrollTo({top: 0, behavior: 'smooth'});

  }
  getBrochure(){
    //this.headerService.updateAdminFormEvent('clearSearchTerm');
    window.open("http://d1bo46l1lnh354.cloudfront.net/Mahaveer/Brochure/ITR-Lubricants-Brochure.pdf")
  }

  getLatestProducts(){
    this.headerService.updateAdminFormEvent('clearSearchTerm');
    if(config.title==='BombayHardware'|| config.title==='Beaubelle' || config.title==='AvonSeals'){
      // this.router.navigate([`/category-listing`], {queryParams: {}, relativeTo:this.route,  skipLocationChange: false });
      // this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });
      // this.router.navigate([`/productListing`], {queryParams: { terms:[[],['GI'],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
      console.log("check if this is called")
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
     } else {
      this.router.navigate([`/productListing`], {queryParams: { terms:"", type:'last90days' }, relativeTo:this.route,  skipLocationChange: false });
     }
  }

  // getCopyRightText(){
  //   // REQUEST DATA
  //   let request_data = {
  //     "domain_name": "www.mahaveerdistributor.in",
  //     "user_id": 17,
  //     "extras": {
  //         "find": {}
  //     }
  //   }
  //   // MAKE AN API CALL
  //   this.appService.postApiCall(request_data, ENDPOINTS.STORE_SETTINGS).subscribe(
  //     resp => {
  //       let respData = resp.result.data[0];
  //       if(respData.website){
  //         this.copyRightText = respData.website.copyright_text;
  //       }
  //     },
  //     err => {
  //       if (err.error.error.message) {
  //         console.log(err.error.error.message)
  //       }
  //       else{
  //         console.log('Something bad happened; Please try again!')
  //       }
  //     })
  // }
  navigateToCategory(name){
    //this.router.navigate([`/productListing`], {queryParams: { terms:[[],[name],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
    this.router.navigate(['/']).then(()=>
   // this.router.navigate([`/productListing`], { queryParams: { terms: [name], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false }));
    //this.router.navigate([`/productListing`], {queryParams: { terms:[[name],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false }));
   this.router.navigate([`/productListing`], {queryParams: { terms:[[name],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false }));


  }
   navigateToList(category) {
    
      this.localService.set('allProducts','notall')
      console.log("insideee")
      // this.router.navigate([`/productListing`], { queryParams: { terms: [category], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
      this.router.navigate([`/productListing`], {queryParams: { terms:[[],[category],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
  }

  openBranchLocation() {
        window.open("https://www.google.com/maps/place/Avon+Seals+Pvt+Ltd/@13.0902839,80.1740384,15z/data=!4m6!3m5!1s0x3a52639655555555:0x7e2c7e0121a73915!8m2!3d13.0902839!4d80.1740384!16s%2Fg%2F1tf_r9j3?entry=ttu", '_blank');
      }
    }

